import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import { filter, finalize, first, map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { Company } from "./company-raw.types";

@Injectable({
    providedIn: 'root'
})
export class CompanyRawService {
    public _companies: BehaviorSubject<Company[] | null> = new BehaviorSubject(null);
    private _computing: boolean = false;

    constructor(protected _httpClient: HttpClient) {

    }

    get companies$(): Observable<Company[]> {
        return this._companies.asObservable();
    }

    getCompanies(): Observable<Company[]> {

        if (this._computing) {
            return this._companies.pipe(filter(v => v != null),  first())
        }

        if (this._companies.value == null) {
            this._computing = true
            return this._httpClient.get<{
                name: string;
                issuerId: number;
                leiOrCik: { scheme: string, value: string }[];
                tags: string[];
                country: string;
                sector: string;
                companyNameInReports: string[];
                reportIds: number[];
                reportYears: number[];
                reportDates: string[];
                reportAddresses: string[];
                reportContents: boolean[];
                groupNames: string[];
            }[]>(environment.server + 'api/issuer/issuerlist').pipe(
                finalize(() => this._computing = false), 
                map(response => {
                    console.log("Response from: " + environment.server + 'api/issuer/issuerlist');
                    const formattedResponse : Company[] = response.map(c => {
                        return {
                            ...c,
                            reports: c.reportIds.map((rid, i) => { return { id: rid, period: c.reportYears[i], publicationDate: c.reportDates[i], reportAddress: c.reportAddresses[i], reportContent: c.reportContents[i] } }),
                        }
                    })
                    formattedResponse.sort((a, b) => a.name.localeCompare(b.name))
                    this._companies.next(formattedResponse);
                    return formattedResponse;
                })
            );
        }
        else {
            return of(this._companies.value);
        }

    }
}

@Injectable({
    providedIn: 'root'
})
export class CompanyRawResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _companyService: CompanyRawService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        console.log("resolving companies-raw");
        return this._companyService.getCompanies()
    }
}
